import React from "react"
import { Link, useStaticQuery } from "gatsby"
import SlideMain from "../components/SlideMain"
import WorkCard from "../components/WorkCard"
import { FaArrowRight } from "react-icons/fa"
import WrapperRight from "../components/WrapperRight"
import { TitleText } from "../components/styled"
import ArticleCard from "../components/ArticleCard"
import useTypes from "../hooks/useTypes"
import useBestJobs from "../hooks/useBestJobs"
import useMarks from "../hooks/useMarks"
import SearchJob from "../components/SearchJob"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Seo } from "../components/seo"
const IndexPageOLD = () => {
  const dataTypes = useTypes()
  const dataBestJobs = useBestJobs()
  const dataMarks = useMarks()

  const query = useStaticQuery(graphql`
    {
      allStrapiJobs(
        sort: { order: DESC, fields: DatePublication }
        limit: 8
        filter: { Archived: { eq: false }, Language: { eq: "Japanese" } }
      ) {
        nodes {
          JobDescription
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          DatePublication(formatString: "YYYY年 MM月 DD日")
          locations {
            name
          }
          marks {
            name
          }
          types {
            name
          }
        }
      }
      allStrapiTitles(
        limit: 5
        sort: { order: DESC, fields: DatePublication }
      ) {
        nodes {
          Content
          Title
          Description
          Slug
          Image {
            publicURL
          }
          LastUpdate
          DatePublication
          article_categories {
            Name
            Slug
          }
        }
      }

      file(relativePath: { eq: "03_slide_0222.webp" }) {
        publicURL
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const imageLog = query.file.publicURL
  const dataJobs = query.allStrapiJobs.nodes
  const dataArticles = query.allStrapiTitles.nodes

  return (
    <>
      <Seo
        title={`メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
        image={imageLog}
      />
      <SlideMain></SlideMain>
      <section className="container wrapper">
        <div className="row">
          <div className="wrapper__left col-lg-9 col-lg-9">
            <p className="wrapper__left__quick-text">
              QUICK GLOBAL MEXICO, S.A. DE C.V. は、 <br />
              メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントです。
              <br />
              メキシコ国内だけでなく、日本からの就職・転職もサポートしており、
              <br />
              在メキシコの日系企業の採用のご支援も行っています。
            </p>
            <div className="wrapper__left__cards">
              <TitleText> 職種からお仕事を探す</TitleText>
              <div className="content-cardWorks u-padding-bottom-3">
                {dataTypes.map((works, i) => (
                  <WorkCard key={i} works={works} />
                ))}
                <Link className="btn-list-jobs" to="/jobs-listing">
                  求人一覧はこちら
                </Link>
              </div>
            </div>
            <div className="wrapper__left__searchBar u-padding-bottom-3">
              <TitleText> キーワードからお仕事を探す</TitleText>
              <div className="jobs-listing__search--box">
                <SearchJob />
              </div>

              <ul>
                {dataMarks.map((mark, i) => (
                  <li key={i}>
                    <Link to={`/job-search/${mark.name}`}>{mark.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="wrapper__left__recommendedJobs u-padding-bottom-3">
              <TitleText>{dataBestJobs[0].title}</TitleText>
              <ul>
                {dataBestJobs[0].topjobs.map((job, i) => (
                  <li key={i}>
                    <Link
                      to={`/osusume${job.Link}#matome0${i + 1}`}
                      onClick={() => scrollTo(`#matome0${i + 1}`)}
                    >
                      {job.Title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="wrapper__left__infoJobs u-padding-bottom-3">
              <div className="u-border-bottom-gl u-flex-space-between">
                <TitleText>求人情報</TitleText>
                <Link to="/jobs-listing">
                  求人情報 一覧 <FaArrowRight />
                </Link>
              </div>
              <ul>
                {dataJobs.map((infoJob, i) => (
                  <li key={i}>
                    <span>{infoJob.DatePublication}</span>
                    <Link to={`/jobs-listing/${infoJob.Manualid}`}>
                      {infoJob.Title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="wrapper__left__news u-padding-bottom-3">
              <div className="u-border-bottom-gl u-flex-space-between">
                <TitleText>記事</TitleText>
                <Link className="wrapper__left__news--news-list" to="/info">
                  記事一覧 <FaArrowRight />
                </Link>
              </div>
              {dataArticles.map((news, i) => (
                <ArticleCard key={i} article={news} />
              ))}
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default IndexPageOLD
