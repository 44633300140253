import React, { useContext } from "react"
import { Link } from "gatsby"
import { SearchJobContext } from "../context/SearchJobContext"

const WorkCard = data => {
  const { setMark, setTagLocation, setTagOnly } = useContext(SearchJobContext)
  const handleChangeSearch = typeValue => {
    setMark([])
    setTagLocation([])
    setTagOnly([])
    const valueInput = typeValue
    setMark(prevState => [...prevState, valueInput])
    // setTagLocation(prevState => [...prevState, valueInput])
    // setTagOnly(prevState => [...prevState, valueInput])
  }  
  return (
    <Link
      to={`/jobs-search/${data.works.name}`}
      onClick={() => handleChangeSearch(data.works.name)}
      className="content-cardWorks__card"
    >
      {data.works.image === null ? (
        ""
      ) : (
        <img src={data.works.image.publicURL} alt={data.works.name} />
      )}
      <div><span
      dangerouslySetInnerHTML={{
                                  __html: data.works.title,
                                }}></span></div>
    </Link>
  )
}

export default WorkCard

// !Ctm2nn!j9Ea