import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
// React Slick
import Slider from "react-slick"
// Components

const SlideMain = () => {
  // Query to bring the main slide images
  const dataSlides = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
          relativeDirectory: { eq: "slide-image" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1800, jpegQuality: 100, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)
  const slides = dataSlides.allFile.nodes
  const settings = {
    dots: true,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
  }
  return (
    <Slider {...settings}>
      {slides.map((slide, i) => (
        <div key={i}>
          <Img          
            fluid={slide.childImageSharp.fluid}
          />
        </div>
      ))}
    </Slider>
  )
}

export default SlideMain
